import { loadItems, read } from "./utilis";

const initState = {
  loading: false,
  items: [],
  fetchError: null
};

const actionTypes = {
  R: "dashboard/CURRENT_WEEK"
};

export default function reducer(state = initState, action) {
  return read(state, action, actionTypes);
}

export const loadDashboardCurrentWeek = loadItems(actionTypes.R, {
  url: "/api/dashboard",
  method: "get"
});
