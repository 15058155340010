import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";

import { hideSnackbar } from "store/reducers/notification";

function GlobalSnackbar(props) {
  const { noficationReducer, hideSnackbar } = props;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={noficationReducer.snackbar}
      autoHideDuration={5000}
      onClose={hideSnackbar}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{noficationReducer.snackbarInfo}</span>}
      action={[
        <IconButton
          key="close"
          onClick={hideSnackbar}
          aria-label="Close"
          color="inherit"
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
}

const mapStateToProps = state => {
  return {
    noficationReducer: state.notification
  };
};

export default connect(
  mapStateToProps,
  { hideSnackbar }
)(GlobalSnackbar);
