const SHOW_SNACKBAR = "notification/SHOW_SNACKBAR";
const HIDE_SNACKBAR = "notification/HIDE_SNACKBAR";

const initState = {
  snackbar: false,
  snackbarInfo: null
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: true,
        snackbarInfo: action.info
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbar: false,
        snackbarInfo: null
      };
    default:
      return state;
  }
}

export function showSnackbar(info) {
  return {
    type: SHOW_SNACKBAR,
    info
  };
}

export function hideSnackbar() {
  return {
    type: HIDE_SNACKBAR
  };
}
