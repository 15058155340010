import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { connect } from "react-redux";

import Header from "../Header";
import Sidebar from "../Sidebar";
import { logout } from "store/reducers/authenticate";

const styles = (theme) => ({
  root: {
    // display: "flex",
    height: "inherit",
  },
  content: {
    flexGrow: 1,
    marginLeft: 60,
    padding: "0px 20px",
    // marginTop: 60,
  },
});

function MainLayout(props) {
  const { classes, children } = props;
  return (
    <Fragment>
      <div className={classes.root}>
        <Header logout={props.logout} />
        <main className={classNames(classes.content)}>
          <React.Fragment>{children}</React.Fragment>
        </main>
      </div>
      <Sidebar />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { logout })(
  withStyles(styles)(MainLayout)
);
