import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const contractorBillsAdapter = createEntityAdapter();

const contractorBillSlice = createSlice({
  name: "contractorBills",
  initialState: contractorBillsAdapter.getInitialState({
    loading: "idle",
    currentPage: 0,
    total: 0,
  }),
  reducers: {
    contractorBillAdded(state, action) {
      state.total = state.total + 1;
      if (state.ids.length < 10) {
        contractorBillsAdapter.addOne(state, action.payload);
      }
    },
    contractorBillRemoved(state, action) {
      state.total = state.total - 1;
      contractorBillsAdapter.removeOne(state, action.payload);
    },
    contractorBillsLoading(state, action) {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    contractorBillsReceived(state, action) {
      if (state.loading === "pending") {
        contractorBillsAdapter.setAll(state, action.payload.data);
        state.loading = "idle";
        state.total = action.payload.total;
        state.currentPage = action.payload.current_page;
      }
    },
    contractorBillUpdated: contractorBillsAdapter.updateOne,
  },
});

export const {
  contractorBillAdded,
  contractorBillsLoading,
  contractorBillsReceived,
  contractorBillUpdated,
  contractorBillRemoved,
} = contractorBillSlice.actions;

export default contractorBillSlice.reducer;

export const getContractorBills = (state) => state.contractorBill.entities;

export const getContractorBillPaginationValues = (state) => {
  return {
    total: state.contractorBill.total,
    currentPage: state.contractorBill.currentPage,
  };
};
