import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import NotFound from "./NotFound";

export default function Loading({ error }) {
  if (error) {
    return <NotFound />;
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}
