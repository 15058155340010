import React from "react";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import SearchIcon from "@material-ui/icons/SearchOutlined";

const styles = (theme) => ({
  root: {
    marginLeft: 60,
    display: "grid",
    gridTemplateColumns: "1fr 100px",
    alignItems: "center",
    justifyItems: "center",
    marginTop: 16,
    marginBottom: 16,
  },
  textFieldRoot: {
    width: "45%",
  },
});

const Header = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <TextField
        classes={{ root: classes.textFieldRoot }}
        fullWidth
        variant="outlined"
        placeholder="Search Client, Site, Supplier, Contractor"
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
      <Avatar>A</Avatar>
    </div>
  );
};

export default withRouter(withStyles(styles)(Header));
