import React from "react";
import { Provider } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import axios from "axios";
import cookies from "browser-cookies";
import { SnackbarProvider } from "notistack";

import store from "store";
import GlobalSnackbar from "components/GlobalSnackbar";
import AppContainer from "components/AppContainer";

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green,
  },
  status: {
    danger: "orange",
  },
});

axios.defaults.baseURL = 'https://civilapi.marscube.in'

axios.interceptors.request.use(
  function (config) {
    const authKey = cookies.get("SID");
    if (authKey && !config.headers.Authorization) {
      config.headers = {
        ContentType: "application/json",
        Authorization: `Bearer ${authKey}`,
        Accept: "application/json",
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function App() {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <GlobalSnackbar />
            <AppContainer />
          </SnackbarProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}

export default App;
