import { loadPaginatedItems, read } from "./utilis";

const initState = {
  loading: false,
  items: {},
  fetchError: null,
};

const actionTypes = {
  R: "expense/LOAD_ITEMS",
  UPDATE: "expense/UPDATE",
};

export default function reducer(state = initState, action) {
  if (actionTypes.UPDATE === action.type) {
    const newData = action.isNew
      ? [action.payload, ...state.items.data]
      : state.items.data.map((e) => {
          if (e.id === action.payload.id) {
            return action.payload;
          }
          return e;
        });
    return {
      ...state,
      items: {
        ...state.items,
        date: newData,
      },
    };
  }
  return read(state, action, actionTypes, true);
}

export const loadExpenses = (params) =>
  loadPaginatedItems(actionTypes.R, params, "load", "/api/expenses");

export const updateExpense = (expense, isNew) => {
  return {
    type: actionTypes.UPDATE,
    payload: expense,
    isNew: isNew,
  };
};
