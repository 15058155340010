import { loadItems, read } from "./utilis";
import get from "lodash/get";

const initState = {
  loading: false,
  items: {
    expense_types: [],
    materials: [],
    contractors: [],
    contractorTypes: [],
    clients: [],
    suppliers: [],
  },
  fetchError: null,
};

const actionTypes = {
  R: "common/COMMON_DATA",
};

export default function reducer(state = initState, action) {
  if (action.type === "common/ADD_CLIENT") {
    let clients = state.items.clients;
    if (action.id) {
      clients = clients.map((client) => {
        if (client.id === action.id) {
          return action.client;
        }
        return client;
      });
    } else {
      clients = [...clients, action.client];
    }
    return {
      ...state,
      items: {
        ...state.items,
        clients,
      },
    };
  }
  if (action.type === "common/ADD_CONTRACTOR") {
    let contractors = state.items.contractors;
    if (action.id) {
      contractors = contractors.map((contractor) => {
        if (contractor.id === action.id) {
          return action.contractor;
        }
        return contractor;
      });
    } else {
      contractors = [...contractors, action.contractor];
    }
    return {
      ...state,
      items: {
        ...state.items,
        contractors,
      },
    };
  }
  if (action.type === "common/ADD_CONTRACTOR_TYPE") {
    let contractorTypes = state.items.contractorTypes;
    if (action.id) {
      contractorTypes = contractorTypes.map((contractorType) => {
        if (contractorType.id === action.id) {
          return action.contractorType;
        }
        return contractorType;
      });
    } else {
      contractorTypes = [...contractorTypes, action.contractorType];
    }
    return {
      ...state,
      items: {
        ...state.items,
        contractorTypes,
      },
    };
  }
  if (action.type === "common/ADD_EXPENSE_TYPE") {
    let expenseTypes = state.items.expenseTypes;
    if (action.id) {
      expenseTypes = expenseTypes.map((expenseType) => {
        if (expenseType.id === action.id) {
          return action.expenseType;
        }
        return expenseType;
      });
    } else {
      expenseTypes = [...expenseTypes, action.expenseType];
    }
    return {
      ...state,
      items: {
        ...state.items,
        expenseTypes,
      },
    };
  }
  if (action.type === "common/ADD_SUPPLIER") {
    let suppliers = state.items.suppliers;
    if (action.id) {
      suppliers = suppliers.map((supplier) => {
        if (supplier.id === action.id) {
          return action.supplier;
        }
        return supplier;
      });
    } else {
      suppliers = [...suppliers, action.supplier];
    }
    return {
      ...state,
      items: {
        ...state.items,
        suppliers,
      },
    };
  }
  return read(state, action, actionTypes);
}

export function addClient(client, id) {
  return {
    type: "common/ADD_CLIENT",
    client,
    id,
  };
}

export function addContractor(contractor, id) {
  return {
    type: "common/ADD_CONTRACTOR",
    contractor,
    id,
  };
}

export function addSupplier(supplier, id) {
  return {
    type: "common/ADD_SUPPLIER",
    supplier,
    id,
  };
}

export function addContractorType(contractorType, id) {
  return {
    type: "common/ADD_CONTRACTOR_TYPE",
    contractorType,
    id,
  };
}

export function addExpenseType(expenseType, id) {
  return {
    type: "common/ADD_EXPENSE_TYPE",
    expenseType,
    id,
  };
}

export const loadCommonData = loadItems(actionTypes.R, {
  url: "/api/common",
  method: "get",
});

export const getExpenseTypes = (state) => {
  return get(state.common.items, "expense_type", []);
};

export const getContractors = (state) => {
  return get(state.common.items, "contractors", []);
};

export const getMaterialSupplier = (state) => {
  return get(state.common.items, "suppliers", []);
};

export const getMaterialType = (state) => {
  return get(state.common.items, "material_type", []);
};

export const getMaterialCategoryType = (state) => {
  return get(state.common.items, "material_category_type", []);
};
