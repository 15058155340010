import React, { Component } from "react";
import { connect } from "react-redux";
import Loadable from "react-loadable";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { DashboardRoute, EmptyRoute } from "components/Routes";
import NotFound from "components/NotFound";
import Loading from "components/Loading";

import { checkLogin } from "store/reducers/authenticate";
// import AttendanceDaily from '../pages/AttendanceDaily';

const SiteListsPage = Loadable({
  loader: () => import("../pages/SiteLists"),
  loading: Loading,
});

const SignInPage = Loadable({
  loader: () => import("../pages/Signin"),
  loading: Loading,
});

const DashboardPage = Loadable({
  loader: () => import("../pages/Dashboard"),
  loading: Loading,
});

const SiteOverViewPage = Loadable({
  loader: () => import("../pages/SiteOverView"),
  loading: Loading,
});

const EntriesPage = Loadable({
  loader: () => import("../pages/Entries"),
  loading: Loading,
});

const MasterPage = Loadable({
  loader: () => import("../pages/Master"),
  loading: Loading,
});

const AttendanceOverview = Loadable({
  loader: () => import("../pages/AttendanceOverview"),
  loading: Loading,
});

const AttendanceDaily = Loadable({
  loader: () => import("../pages/AttendanceDaily"),
  loading: Loading,
});

const OutturnOverview = Loadable({
  loader: () => import("../pages/OutturnOverview"),
  loading: Loading,
});

const UsersPage = Loadable({
  loader: () => import("../pages/UsersList"),
  loading: Loading,
});

const BillPage = Loadable({
  loader: () => import("../pages/Bill"),
  loading: Loading,
});

const ExpensePage = Loadable({
  loader: () => import("../pages/Expense"),
  loading: Loading,
});

class AppContainer extends Component {
  state = {
    booting: false,
  };

  componentDidMount() {
    this.props.checkLogin();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.checking !== this.props.auth.checking) {
      this.setState({ booting: false });
    }
  }
  render() {
    const { auth } = this.props;
    const { booting } = this.state;
    if (booting) {
      return <div />;
    }
    return (
      <Router>
        {true ? (
          <Switch>
            <DashboardRoute path="/" exact component={DashboardPage} />
            <DashboardRoute path="/sites/:id" component={SiteOverViewPage} />
            <DashboardRoute path="/sites" component={SiteListsPage} />
            <DashboardRoute path="/entries" component={EntriesPage} />
            <DashboardRoute path="/setting" component={MasterPage} />
            <DashboardRoute path="/bill" component={BillPage} />
            <DashboardRoute path="/users" component={UsersPage} />
            <DashboardRoute path="/expenses" component={ExpensePage} />
            <DashboardRoute
              path="/attendance-overview"
              component={AttendanceOverview}
            />
            <DashboardRoute
              path="/outturn-overview"
              component={OutturnOverview}
            />
            <DashboardRoute
              path="/attendance-daily"
              component={AttendanceDaily}
            />
            <Route path="/signin" render={() => <Redirect to="/" />} />
            <EmptyRoute component={NotFound} />
          </Switch>
        ) : (
          <Switch>
            <EmptyRoute path="/signin" component={SignInPage} />
            <EmptyRoute path="/" component={SignInPage} />
            <Redirect to="/signin" />
          </Switch>
        )}
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { checkLogin })(AppContainer);
