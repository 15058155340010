import axios from "axios";
import cookies from "browser-cookies";
import get from "lodash/get";
import { loadDashboardCurrentWeek } from "./dashboard";
import { loadSites } from "./sites";
import { loadCommonData } from "./common";

const LOGIN = "authentication/LOGIN";
const LOGIN_SUCCESS = "authentication/LOGIN_SUCCESS";
const LOGIN_FAIL = "authentication/LOGIN_FAIL";

const LOGIN_CHECK = "authentication/LOGIN_CHECK";
const LOGIN_CHECK_SUCCESS = "authentication/LOGIN_CHECK_SUCCESS";
const LOGIN_CHECK_FAIL = "authentication/LOGIN_FAIL";

const LOGOUT = "authentication/LOGOUT";

const initState = {
  loading: false,
  error: null,
  token: null,
  checking: false,
  checkingError: null,
  user: null,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
        error: null,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        user: action.payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
      };
    case LOGIN_CHECK:
      return {
        ...state,
        checking: true,
        token: action.token,
      };
    case LOGIN_CHECK_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        checking: false,
      };
    case LOGIN_CHECK_FAIL:
      return {
        ...state,
        checking: false,
        checkingError: action.error,
        token: null,
        user: null,
      };
    default:
      return state;
  }
}

export function login(email, password) {
  return (dispatch) => {
    dispatch({ type: LOGIN });
    axios
      .post("/api/login", { email, password })
      .then((res) => {
        const token = get(res, "data.token", null);
        cookies.set("SID", token);
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        if (token) {
          bootApp(dispatch);
        }
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
          error:
            err && err.response && err.response.data ? err.response.data : null,
        });
      });
  };
}

export function logout() {
  cookies.erase("SID");
  return {
    type: LOGOUT,
  };
}

export function checkLogin() {
  const token = cookies.get("SID");
  return (dispatch) => {
    dispatch(loadSites());
    dispatch(loadCommonData());
    // dispatch({ type: LOGIN_CHECK, token });
    // axios
    //   .get("/api/user", {
    //     headers: {
    //       ContentType: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((res) => {
    //     dispatch({
    //       type: LOGIN_CHECK_SUCCESS,
    //       payload: { user: res.data, token },
    //     });
    //     bootApp(dispatch);
    //   })
    //   .catch((err) => {
    //     cookies.erase("SID");
    //     dispatch({ type: LOGIN_CHECK_FAIL, error: null });
    //   });
  };
}

function bootApp(dispatch) {
  // dispatch(loadDashboardCurrentWeek());
  dispatch(loadSites());
  dispatch(loadCommonData());
}
