import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const supplierBillsAdapter = createEntityAdapter();

const supplierBillSlice = createSlice({
  name: "supplierBills",
  initialState: supplierBillsAdapter.getInitialState({
    loading: "idle",
    currentPage: 0,
    total: 0,
  }),
  reducers: {
    supplierBillAdded(state, action) {
      state.total = state.total + 1;
      if (state.ids.length < 10) {
        supplierBillsAdapter.addOne(state, action.payload);
      }
    },
    supplierBillRemoved(state, action) {
      state.total = state.total - 1;
      supplierBillsAdapter.removeOne(state, action.payload);
    },
    supplierBillsLoading(state, action) {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    supplierBillsReceived(state, action) {
      if (state.loading === "pending") {
        supplierBillsAdapter.setAll(state, action.payload.data);
        state.loading = "idle";
        state.total = action.payload.total;
        state.currentPage = action.payload.current_page;
      }
    },
    supplierBillUpdated: supplierBillsAdapter.updateOne,
  },
});

export const {
  supplierBillAdded,
  supplierBillsLoading,
  supplierBillsReceived,
  supplierBillUpdated,
  supplierBillRemoved,
} = supplierBillSlice.actions;

export default supplierBillSlice.reducer;

export const getsupplierBills = (state) => state.supplierBill.entities;

export const getsupplierBillPaginationValues = (state) => {
  return {
    total: state.supplierBill.total,
    currentPage: state.supplierBill.currentPage,
  };
};
