import axios from "axios";
import find from "lodash/find";
import cookies from "browser-cookies";
import { showSnackbar } from "./notification";

export function read(state, action, actionTypes, isFull = false) {
  switch (action.type) {
    case `${actionTypes.R}_PENDING`:
      return {
        ...state,
        loading: true,
        fetchError: null,
        items: isFull ? {} : [],
      };
    case `${actionTypes.R}_FULFILLED`:
      let { data: successResponse } = action.payload;
      return {
        ...state,
        loading: false,
        items: isFull ? successResponse : successResponse.data,
        fetchError: null,
      };
    case `${actionTypes.R}_REJECTED`:
      const { response } = action.payload;
      return {
        ...state,
        loading: false,
        fetchError: response.data,
      };
    default:
      return state;
  }
}

export function crudReducer(state, action, actionTypes) {
  switch (action.type) {
    case `${actionTypes.R}_PENDING`:
      return {
        ...state,
        loading: true,
        fetchError: null,
      };
    case `${actionTypes.R}_FULFILLED`:
      let { data: successResponse } = action.payload;
      return {
        ...state,
        loading: false,
        items: successResponse.data,
        fetchError: null,
      };
    case `${actionTypes.R}_REJECTED`:
      const { response } = action.payload;
      return {
        ...state,
        loading: false,
        fetchError: response.data,
      };
    case `${actionTypes.CU}_PENDING`:
      return {
        ...state,
        formSubmitting: true,
        formSubmitError: null,
      };
    case `${actionTypes.CU}_FULFILLED`:
      let { data: submitResponse } = action.payload;
      let updatedItems = [];
      if (action.meta.selectedItem) {
        updatedItems = state.items.map((item) => {
          return action.meta.selectedItem.id === item.id
            ? submitResponse.data
            : item;
        });
      } else {
        updatedItems = [...state.items, submitResponse.data];
      }
      return {
        ...state,
        formSubmitting: false,
        items: updatedItems,
      };
    case `${actionTypes.CU}_REJECTED`:
      const {
        response: { status, data },
      } = action.payload;
      return {
        ...state,
        formSubmitting: false,
        formSubmitError: status === 422 ? data : "Internal Error!",
      };
    default:
      return state;
  }
}

export function loadItems(type, axiosConfig, metaData = {}) {
  return function () {
    return {
      type,
      payload: axios({
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${cookies.get("SID")}`,
        },
        ...axiosConfig,
      }),
      meta: metaData,
    };
  };
}

export function loadPaginatedItems(type, params, loadType, url = "") {
  return {
    type,
    payload: axios({
      headers: {
        ContentType: "application/json",
        Authorization: `Bearer ${cookies.get("SID")}`,
      },
      url,
      method: "get",
      params,
    }),
    meta: { loadType },
  };
}

export function deleteItem(type, id, url = "") {
  return (dispatch) => {
    const response = dispatch({
      type,
      payload: axios.delete(url + id, {
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${cookies.get("SID")}`,
        },
      }),
      meta: { id },
    }).catch((error) => {
      dispatch(showSnackbar("Try again Later"));
    });

    response.then((data) => {
      if (data) {
        dispatch(showSnackbar("Deleted Successfully"));
      }
    });
  };
}

export function updatePageNo(type, pageNumber) {
  return {
    type,
    pageNumber,
  };
}

export function submitForm(data, selectedItem, type = "", baseUrl = "") {
  const method = selectedItem ? "put" : "post";
  const url = selectedItem ? `${baseUrl}/${selectedItem.id}` : baseUrl;

  return (dispatch) => {
    const response = dispatch({
      type,
      payload: axios({
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${cookies.get("SID")}`,
        },
        method,
        url,
        data,
      }),
      meta: { selectedItem },
    }).catch((error) => {
      dispatch(showSnackbar("Try again Later"));
    });

    response.then((data) => {
      if (data) {
        if (data.action.meta.selectedItem) {
          dispatch(showSnackbar("Updated Successfully"));
        } else {
          dispatch(showSnackbar("Created Successfully"));
        }
      }
    });
  };
}

export function crudReducerWithPagination(state, action, actionTypes) {
  switch (action.type) {
    case `${actionTypes.R}_PENDING`:
      return {
        ...state,
        loading: true,
        fetchError: null,
      };
    case `${actionTypes.R}_FULFILLED`:
      let { data: successResponse } = action.payload;
      // check to aviod adding a new one again to list
      const newItems = successResponse.data.filter((s) => {
        const isAlreadyAddedItem = find(state.items, { id: s.id });
        if (isAlreadyAddedItem && isAlreadyAddedItem.newOne) {
          return false;
        }
        return true;
      });
      let availableItems =
        action.meta.loadType === "refresh"
          ? successResponse.data
          : [...state.items, ...newItems];
      return {
        ...state,
        loading: false,
        items: availableItems,
        fetchError: null,
        total: successResponse.total,
        last_page: successResponse.last_page,
        current_page: successResponse.current_page,
      };
    case `${actionTypes.R}_REJECTED`:
      const { response } = action.payload;
      return {
        ...state,
        loading: false,
        fetchError: response.data,
      };
    case `${actionTypes.CU}_PENDING`:
      return {
        ...state,
        formSubmitting: true,
        formSubmitError: null,
      };
    case `${actionTypes.CU}_FULFILLED`:
      let { data: submitResponse } = action.payload;
      let updatedItems = [];
      if (action.meta.selectedItem) {
        updatedItems = state.items.map((party) => {
          return action.meta.selectedItem.id === party.id
            ? submitResponse.data
            : party;
        });
      } else {
        const item = { ...submitResponse.data, newOne: true };
        updatedItems = [...state.items, item];
      }
      return {
        ...state,
        formSubmitting: false,
        items: updatedItems,
        total: action.meta.selectedItem ? state.total : state.total + 1,
      };
    case `${actionTypes.CU}_REJECTED`:
      const {
        response: { status, data },
      } = action.payload;
      return {
        ...state,
        formSubmitting: false,
        formSubmitError: status === 422 ? data : "Internal Error!",
      };
    case `${actionTypes.UP}`:
      return {
        ...state,
        current_page: action.pageNumber,
      };
    case `${actionTypes.D}_PENDING`:
      return {
        ...state,
        deleting: true,
        delResError: null,
      };
    case `${actionTypes.D}_FULFILLED`:
      return {
        ...state,
        deleting: false,
        delResError: null,
        items: state.items.filter((s) => s.id !== action.meta.id),
        total: state.total - 1,
      };
    case `${actionTypes.D}_REJECTED`:
      const { response: delResponse } = action.payload;
      return {
        ...state,
        deleting: false,
        delResError: delResponse,
      };
    default:
      return state;
  }
}

export function siteDetailData(action, state, actionType, detailType) {
  switch (action.type) {
    case `${actionType}_PENDING`:
      return {
        ...state,
        [detailType]: {
          ...state[detailType],
          [action.meta.siteId]: {
            loading: true,
            error: null,
            data: [],
          },
        },
      };
    case `${actionType}_FULFILLED`:
      let { data: successResponse } = action.payload;
      return {
        ...state,
        [detailType]: {
          ...state[detailType],
          [action.meta.siteId]: {
            loading: false,
            error: null,
            data: successResponse.data,
          },
        },
      };
    case `${actionType}_REJECTED`:
      const { response } = action.payload;
      return {
        ...state,
        [detailType]: {
          ...state[detailType],
          [action.meta.siteId]: {
            loading: false,
            error: response.data,
            data: [],
          },
        },
      };
    default:
      return state;
  }
}
