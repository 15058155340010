import { combineReducers } from "redux";

import auth from "./authenticate";
import notification from "./notification";
import dashboard from "./dashboard";
import common from "./common";
import sites from "./sites";
import expense from "./expense";
import contractorBill from "./contractorBills";
import supplierBill from "./supplierBill";

const reducers = combineReducers({
  auth,
  notification,
  dashboard,
  sites,
  common,
  expense,
  contractorBill,
  supplierBill,
});

export default reducers;
