import React from "react";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import AssignmentIndIcon from "@material-ui/icons/AssignmentIndOutlined";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AupervisorAccountIcon from "@material-ui/icons/SupervisorAccountOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import DomainIcon from "@material-ui/icons/Domain";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = (theme) => ({
  drawerPaperClose: {
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) - 4,
    },
  },
  linkRoot: {
    textDecoration: "none",
    "&>div": {
      borderLeft: `3px solid white`,
    },
  },
  linkActiveRoot: {
    "&>div": {
      backgroundColor: "#e1e1e1",
      borderLeft: `3px solid ${theme.palette.primary.dark}`,
    },
    "&>div>div>svg": {
      color: theme.palette.primary.main,
    },
    "&>div>div>span": {
      fontWeight: "600",
    },
  },
});

const Sidebar = (props) => {
  const { classes } = props;
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(classes.drawerPaperClose),
      }}
    >
      <List>
        <NavLink
          to="/"
          exact={true}
          activeClassName={classes.linkActiveRoot}
          className={classes.linkRoot}
        >
          <ListItem button>
            <Tooltip title="Dashboard">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
        <NavLink
          activeClassName={classes.linkActiveRoot}
          exact={true}
          className={classes.linkRoot}
          to="/sites"
        >
          <ListItem button>
            <Tooltip title="Sites">
              <ListItemIcon>
                <DomainIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
        <NavLink
          activeClassName={classes.linkActiveRoot}
          exact={true}
          className={classes.linkRoot}
          to="/expenses"
        >
          <ListItem button>
            <Tooltip title="Expense">
              <ListItemIcon>
                <LocalAtmOutlinedIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
        <NavLink
          activeClassName={classes.linkActiveRoot}
          exact={true}
          className={classes.linkRoot}
          to="/attendance-overview"
        >
          <ListItem button>
            <Tooltip title="Attendance">
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
        <NavLink
          activeClassName={classes.linkActiveRoot}
          exact={true}
          className={classes.linkRoot}
          to="/outturn-overview"
        >
          <ListItem button>
            <Tooltip title="Outturn">
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
        <NavLink
          activeClassName={classes.linkActiveRoot}
          exact={true}
          className={classes.linkRoot}
          to="/users"
        >
          <ListItem button>
            <Tooltip title="Users">
              <ListItemIcon>
                <AupervisorAccountIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
        <NavLink
          activeClassName={classes.linkActiveRoot}
          exact={true}
          className={classes.linkRoot}
          to="/setting"
        >
          <ListItem button>
            <Tooltip title="Settings">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
        <NavLink
          activeClassName={classes.linkActiveRoot}
          exact={true}
          className={classes.linkRoot}
          to="/attendance-daily"
        >
          <ListItem button>
            <Tooltip title="Daily Attendance">
              <ListItemIcon>
                <GroupAddOutlinedIcon />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
        <NavLink
          activeClassName={classes.linkActiveRoot}
          exact={true}
          className={classes.linkRoot}
          to="/bill"
        >
          <ListItem button>
            <Tooltip title="Bill">
              <ListItemIcon>
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                  >
                    <g>
                      <rect fill="none" height="24" width="24" />
                      <path d="M19,14V6c0-1.1-0.9-2-2-2H3C1.9,4,1,4.9,1,6v8c0,1.1,0.9,2,2,2h14C18.1,16,19,15.1,19,14z M17,14H3V6h14V14z M10,7 c-1.66,0-3,1.34-3,3s1.34,3,3,3s3-1.34,3-3S11.66,7,10,7z M23,7v11c0,1.1-0.9,2-2,2H4c0-1,0-0.9,0-2h17V7C22.1,7,22,7,23,7z" />
                    </g>
                  </svg>
                </SvgIcon>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </NavLink>
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(Sidebar);
