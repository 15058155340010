import cookies from "browser-cookies";
import axios from "axios";
import get from "lodash/get";

import { crudReducer, submitForm, loadItems, siteDetailData } from "./utilis";

const initState = {
  items: [],
  loading: false,
  fetchError: null,
  formSubmitting: false,
  formSubmitError: null,
  summary: {},
  expenses: {},
  payments: {},
  attendances: {},
};

const siteDetailedPageActions = {
  S: "SITE_DETAILED_SUMMARY",
  E: "SITE_DETAILED_EXPENSES",
  P: "SITE_DETAILED_PAYMENTS",
  A: "SITE_DETAILED_ATTENDANCES",
};

const SITE_DETAIL_UPDATE = "siteoverview/UPDATE";
const SITE_UPDATE = "site/ADDORUPDATE";
const SITE_ITEM_DELETE = "siteoverview/DELETE";

const actionTypes = {
  R: "sites/FETCH_SITES",
  CU: "sites/SUBMIT_SITE_FORM",
};

export default function reducer(state = initState, action) {
  if (
    action.type.includes(actionTypes.R) ||
    action.type.includes(actionTypes.CU)
  ) {
    return crudReducer(state, action, actionTypes);
  }
  if (action.type.includes(siteDetailedPageActions.S)) {
    return siteDetailData(action, state, siteDetailedPageActions.S, "summary");
  }
  if (action.type.includes(siteDetailedPageActions.E)) {
    return siteDetailData(action, state, siteDetailedPageActions.E, "expenses");
  }
  if (action.type.includes(siteDetailedPageActions.P)) {
    return siteDetailData(action, state, siteDetailedPageActions.P, "payments");
  }
  if (action.type.includes(siteDetailedPageActions.A)) {
    return siteDetailData(
      action,
      state,
      siteDetailedPageActions.A,
      "attendances"
    );
  }
  if (action.type === SITE_DETAIL_UPDATE) {
    let sitePrevData = get(
      state,
      `${action.itemType}.${action.siteId}.data`,
      []
    );
    let siteNewData = [];
    if (action.mode === "edit") {
      siteNewData = sitePrevData.map((item) => {
        if (item.id === action.item.id) {
          return action.item;
        }
        return item;
      });
    } else {
      siteNewData = [...sitePrevData, action.item];
    }
    return {
      ...state,
      [action.itemType]: {
        ...state[action.itemType],
        [action.siteId]: {
          ...state[action.itemType][action.siteId],
          data: siteNewData,
        },
      },
    };
  }
  if (action.type === SITE_ITEM_DELETE) {
    const sitePrevData = get(
      state,
      `${action.itemType}.${action.item.site_id}.data`,
      []
    );
    const siteNewData = sitePrevData.filter(
      (item) => item.id !== action.item.id
    );
    return {
      ...state,
      [action.itemType]: {
        ...state[action.itemType],
        [action.item.site_id]: {
          ...state[action.itemType][action.item.site_id],
          data: siteNewData,
        },
      },
    };
  }
  if (action.type === SITE_UPDATE) {
    let sites = state.items;
    if (action.siteId) {
      sites = sites.map((site) => {
        if (site.id === action.siteId) {
          return action.site;
        }
        return site;
      });
    } else {
      sites = [...sites, action.site];
    }
    return {
      ...state,
      items: sites,
    };
  }
  return state;
}

export const loadSites = loadItems(actionTypes.R, {
  method: "get",
  url: "/api/sites",
});

export function loadSiteDetail(type, siteId) {
  let url = `/api/sites/${siteId}`;
  if (type === "E") {
    url = `/api/expenses?site=${siteId}`;
  }
  if (type === "P") {
    url = `/api/payments?site=${siteId}`;
  }
  if (type === "A") {
    url = `/api/attendances?site=${siteId}`;
  }
  return {
    type: siteDetailedPageActions[type],
    payload: axios({
      headers: {
        ContentType: "application/json",
        Authorization: `Bearer ${cookies.get("SID")}`,
      },
      url: url,
      method: "get",
      params: { type },
    }),
    meta: { siteId },
  };
}

export const submitSiteForm = (formValues, selectedParty) => {
  const data = {
    name: formValues.name,
    phoneno: formValues.phone,
    address: formValues.address,
  };
  return submitForm(data, selectedParty, actionTypes.CU, "/api/sites");
};

export function addOrUpdateSiteInfo(item, type, site, mode) {
  return {
    type: SITE_DETAIL_UPDATE,
    itemType: type,
    siteId: site,
    mode,
    item,
  };
}

export function addOrUpdateSite(site, siteId) {
  return {
    type: SITE_UPDATE,
    siteId,
    site,
  };
}

export function removeSiteItem(item, itemType) {
  return {
    type: SITE_ITEM_DELETE,
    item,
    itemType,
  };
}

export const activeSites = (state) => {
  return state.sites.items;
};
